import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {LinkModel} from '../../../models';
import {JobsService} from '../../../services';

@Component({
    selector: 'app-add-link-dialog',
    templateUrl: './add-link-dialog.component.html',
    styleUrls: ['./add-link-dialog.component.scss']
})
export class AddLinkDialogComponent {
    memberLink: LinkModel | undefined = {
        jobId: null,
        title: '',
        url: '',
        active: true
    };
    saving$: Observable<boolean>;

    constructor(
        private jobsService: JobsService,
        public dialogRef: MatDialogRef<AddLinkDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBar: MatSnackBar,
    ) {
        this.saving$ = this.jobsService.isSaving$;
        this.memberLink.jobId = data.jobId;
    }

    saveLink() {
        if (!this.memberLink.title || !this.memberLink.url) {
            this.openErrorSnackbar('Please provide both title and URL');
            return;
        }
        const jobId = this.memberLink.jobId;

        this.jobsService.saveJobLink(jobId, this.memberLink.title, this.memberLink.url).subscribe(
            () => {
                this.dialogRef.close({
                    jobId: jobId,
                    title: this.memberLink.title,
                    url: this.memberLink.url
                });
            },
            error => {
                const readableMessage = error?.error?.message || 'Error saving member link';
                this.openErrorSnackbar(readableMessage);
                this.closeDialog();
            }
        );
    }

    closeDialog() {
        this.dialogRef.close();
    }

    private openErrorSnackbar(message: string) {
        this.snackBar.open(message, 'Close', {
            duration: 3000
        });
    }
}

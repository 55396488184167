import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import {AddEditJobComponent} from 'src/app/components/add-edit-job/add-edit-job.component';
import {FixInvoiceComponent} from 'src/app/components/fix-invoice/fix-invoice.component';
import {JobAlbumComponent} from 'src/app/components/job-album/job-album.component';
import {ProductBucketComponent} from 'src/app/components/job-album/product-bucket/product-bucket.component';
import {JobsComponent} from 'src/app/components/jobs/jobs.component';
import {InvoiceDisplayComponent} from '../../components';
import {HomeCoreRouting} from '../routing/home-core.routing';
import {SharedModule} from './shared.module';
import {JobsTableComponent} from 'src/app/components/jobs/jobs-table/jobs-table.component';
import {IgxDragDirective, IgxDropDirective} from 'igniteui-angular';
import {AngularSvgIconModule} from 'angular-svg-icon';
import { AddLinkDialogComponent } from '../../components/job-album/add-link-dialog/add-link-dialog.component';
import {LinkListComponent} from '../../components/job-album/link-list/link-list.component';
import { EditLinkDialogComponent} from '../../components/job-album/edit-link-dialog/edit-link-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        HomeCoreRouting,
        OverlayModule,
        IgxDragDirective,
        IgxDropDirective,
        AngularSvgIconModule
    ],
    declarations: [
        JobsComponent,
        JobsTableComponent,
        JobAlbumComponent,
        ProductBucketComponent,
        AddEditJobComponent,
        InvoiceDisplayComponent,
        FixInvoiceComponent,
        AddLinkDialogComponent,
        LinkListComponent,
        EditLinkDialogComponent
    ],
    exports: []
})
export class HomeCoreModule {
}

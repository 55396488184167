import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {LinkModel} from '../../../models';
import {SnackbarService} from '../../../services';

@Component({
    selector: 'app-link-list',
    templateUrl: './link-list.component.html',
    styleUrls: ['./link-list.component.scss']
})
export class LinkListComponent {
    @Input() links: LinkModel[] = [];
    @ViewChild('linkList') linkList: ElementRef<HTMLUListElement>;

    constructor(private snackbarService: SnackbarService) {
    }

    scrollLeft(): void {
        if (this.linkList) {
            this.linkList.nativeElement.scrollBy({
                left: -400,
                behavior: 'smooth'
            });
        }
    }

    scrollRight(): void {
        if (this.linkList) {
            this.linkList.nativeElement.scrollBy({
                left: 400,
                behavior: 'smooth'
            });
        }
    }

    getThumbnail(url: string, thumbnail?: string): string {
        if (thumbnail) {
            return thumbnail;
        } else if (this.isYouTubeLink(url)) {
            return `https://img.youtube.com/vi/${this.extractVideoId(url)}/0.jpg`;
        }
        return '';
    }

    isYouTubeLink(url: string): boolean {
        return /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)/.test(url);
    }

    extractVideoId(url: string): string {
        const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return videoIdMatch ? videoIdMatch[1] : '';
    }

    copyToClipboard(url: string): void {
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.snackbarService.showSnackbar('Link copied to clipboard');
    }
}

<mat-progress-bar *ngIf="loadingMemberData" mode="indeterminate"></mat-progress-bar>
<div class="personal-info-form" [formGroup]="personalInformationForm">
    <h3>Personal Information</h3>
    <div class="field-group">
        <div class="form-field form-field-input">
            <mat-label>First Name:</mat-label>
            <span class="spacer"></span>
            <mat-form-field appearance="outline">
                <input matInput
                       placeholder="First Name"
                       formControlName="firstName" required>
                <mat-error *ngIf="firstNameControl.errors?.required && firstNameControl.touched">Please enter your first
                    name
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-field form-field-input">
            <mat-label>Last Name:</mat-label>
            <span class="spacer"></span>
            <mat-form-field appearance="outline">
                <input matInput
                       placeholder="Last Name"
                       formControlName="lastName" required>
                <mat-error *ngIf="lastNameControl.errors?.required && lastNameControl.touched">Please enter your last
                    name
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-field form-field-input">
            <mat-label>Login Email:</mat-label>
            <span class="spacer"></span>
            <mat-form-field appearance="outline">
                <input matInput
                       placeholder="Login Email"
                       formControlName="loginEmail">
            </mat-form-field>
        </div>

        <div class="form-field form-field-input">
            <mat-label>Secondary Email:</mat-label>
            <span class="spacer"></span>
            <mat-form-field appearance="outline">
                <input matInput
                       placeholder="Secondary Email"
                       formControlName="secondaryEmail" required>
                <mat-error *ngIf="secondaryEmailControl.errors?.required && secondaryEmailControl.touched">Please enter
                    an email address
                </mat-error>
                <mat-error *ngIf="secondaryEmailControl.errors?.pattern && secondaryEmailControl.touched">Please enter a
                    valid email address
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-field form-field-input">
            <mat-label>Contact Number:</mat-label>
            <span class="spacer"></span>
            <mat-form-field appearance="outline">
                <input matInput
                       placeholder="Contact Number"
                       formControlName="contactNumber" required>
                <mat-error *ngIf="contactNumberControl.errors?.required && contactNumberControl.touched">Please enter a
                    contact number
                </mat-error>
                <mat-error *ngIf="contactNumberControl.errors?.pattern && contactNumberControl.touched">Please enter a
                    valid number
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-field form-field-input" *ngIf="showCompanyFields">
            <mat-label>Company Name:</mat-label>
            <span class="spacer"></span>
            <mat-form-field appearance="outline">
                <input matInput
                       placeholder="Company Name"
                       formControlName="companyName" required>
                <mat-error *ngIf="companyNameControl.errors?.required && companyNameControl.touched">Please enter your company name</mat-error>
            </mat-form-field>
        </div>

        <div class="form-field form-field-input" *ngIf="showCompanyFields">
            <mat-label>Company ID:</mat-label>
            <span class="spacer"></span>
            <mat-form-field appearance="outline">
                <input matInput
                       placeholder="Company ID"
                       formControlName="companyId" required>
                <mat-error *ngIf="companyIdControl.errors?.required && companyIdControl.touched">Please enter your company ID</mat-error>
            </mat-form-field>
        </div>

        <div class="form-field form-field-input">
            <mat-label>Company Name:</mat-label>
            <span class="spacer"></span>
            <mat-form-field appearance="outline">
                <input matInput
                       placeholder="Company Name"
                       formControlName="companyName" required>
                <mat-error *ngIf="companyNameControl.errors?.required && companyNameControl.touched">Please enter your company name</mat-error>
            </mat-form-field>
        </div>

        <div class="form-field form-field-input">
            <mat-label>Company ID:</mat-label>
            <span class="spacer"></span>
            <mat-form-field appearance="outline">
                <input matInput
                       placeholder="Company ID"
                       formControlName="companyId" required>
                <mat-error *ngIf="companyIdControl.errors?.required && companyIdControl.touched">Please enter your company ID</mat-error>
            </mat-form-field>
        </div>

        <div class="form-field form-field-input">
            <mat-label>Postal Address:</mat-label>
            <span class="spacer"></span>
            <mat-form-field appearance="outline">
                <input matInput
                       placeholder="Postal Address"
                       [matAutocomplete]="autoAddress"
                       formControlName="postalAddress" required>
                <mat-error *ngIf="postalAddressControl.errors?.required && postalAddressControl.touched">Please provide
                    your postal address
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-field form-field-input">
            <mat-autocomplete #autoAddress="matAutocomplete">
                <mat-option #autoAddressOption (onSelectionChange)="autoAddressSelectionMade(option)"
                            *ngFor="let option of addressAutoCompleteOptions | async" [value]="option">
                    {{ option }}
                </mat-option>
            </mat-autocomplete>
        </div>

    </div>

    <div class="account-actions">
        <button mat-raised-button
                class="btn btn-blue save-account-btn"
                [disabled]="loadingMemberData || personalInformationForm.invalid || personalInformationForm.pristine"
                (click)="saveAccount()">
            Save My Account
            <ng-template [ngIf]="loadingMemberData">
                <div class="lds-hourglass"></div>
            </ng-template>
        </button>
    </div>
</div>

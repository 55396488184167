<div class="mat-dialog-container">
    <h1 mat-dialog-title>Edit Link</h1>
    <mat-dialog-content>
        <div class="form-container">
            <div class="form-section">
                <p>Please edit the URL link.</p>
                <div class="form-group">
                    <div class="form-field form-field-input">
                        <mat-label class="form-field-label">Title</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput type="text" id="title" placeholder="e.g YouTube video" [(ngModel)]="link.title">
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-field form-field-input">
                        <mat-label class="form-field-label">URL</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput type="text" id="url" placeholder="www.youtube.com" [(ngModel)]="link.url">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button class="btn btn-red cancel-btn" (click)="cancel()">Cancel</button>
        <button mat-raised-button class="btn btn-green" (click)="save()" [disabled]="saving$ | async">
            Save Link
            <ng-template [ngIf]="saving$ | async">
                <div class="lds-hourglass"></div>
            </ng-template>
        </button>
    </mat-dialog-actions>
</div>

import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AuthenticationService, DialogService, MemberService} from '../../services';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PrismHeaders} from '../../enums/prism-headers.enum';
import {environment} from '../../../environments';
import 'firebase/functions';
import {Contractor, Member} from '../../models';
import {Subject} from 'rxjs';

declare var Stripe;

@Component({
    selector: 'stripe-elements',
    templateUrl: './stripe-elements.component.html',
    styleUrls: ['./stripe-elements.component.scss']
})
export class StripeElementsComponent implements OnInit, OnDestroy {
    memberData$ = this.authService.memberData$;
    private subDestroyer$: Subject<void> = new Subject<void>();

    constructor(private authService: AuthenticationService,
                private memberService: MemberService,
                private dialogService: DialogService,
                private http: HttpClient) {
    }

    showStripeLoader = false;
    @Input() amount: number;
    @Input() description: string;
    @ViewChild('cardElement', {static: true}) cardElement: ElementRef;
    @Output() cardAdded = new EventEmitter<boolean>();
    stripe;
    card;
    cardErrors;
    loading = false;
    client_secret;
    member: Member | Contractor;

    ngOnInit() {

        this.stripe = Stripe('pk_test_VeTFZkFqlRVvzsKjzio7WDY400xLou067D');
        const elements = this.stripe.elements({
            fonts: [{cssSrc: 'https://fonts.googleapis.com/css?family=Source+Code+Pro'}],
            locale: 'auto'
        });

        this.member = this.authService.memberCurrentData;

        this.card = elements.create('card', {hidePostalCode: true});

        this.card.mount(this.cardElement.nativeElement);

        this.card.addEventListener('change', ({error}) => {
            this.cardErrors = error && error.message;
        });

        let setupIntent;
        return this.http.get(environment.apiBaseUrl + '/CreateSetupIntent').toPromise().then(response => {
            return response;
        }).then((response) => {
            setupIntent = response['data'];
            this.client_secret = setupIntent.client_secret;
        }).catch(err => console.error(err));

    }

    ngOnDestroy() {
        this.subDestroyer$.next();
    }

    async handleForm() {
        this.showStripeLoader = true;
        this.stripe.handleCardSetup(
            this.client_secret, this.card, {
                payment_method_data: {
                    billing_details: {name: !!this.member ? this.member.firstName : ''}
                }
            }
        ).then((result) => {
            if (result.error) {
                this.cardErrors = result.error.message;
                this.showStripeLoader = false;
            } else {
                if (!this.member.stripeId) {
                    this.createCustomer(result.setupIntent).then((stripeId: string) => {
                        this.attachPayment(result.setupIntent, stripeId);
                    });
                } else {
                    this.attachPayment(result.setupIntent, this.member.stripeId);
                }
            }
        }, err => {
            console.error(err);
            this.showStripeLoader = false;
        });
    }

    attachPayment(intent, stripeId: string) {
        const headers = new HttpHeaders().set(PrismHeaders.FunctionHelperData, JSON.stringify({intent, stripeId}));
        return this.http.get(environment.apiBaseUrl + '/AttachPaymentMethod', {headers}).toPromise().then((result) => {
            this.cardAdded.emit(true);
        }, err => console.error(err)).then(() => {
            const paymentId = intent.payment_method;
            const headers = new HttpHeaders().set(PrismHeaders.FunctionHelperData, JSON.stringify({paymentId, stripeId}));
            return this.http.get(environment.apiBaseUrl + '/SetDefaultPaymentMethod', {headers}).toPromise().then((result) => {
                this.dialogService.closeDialog();

            }).catch(err => {
                console.error(err);
                this.showStripeLoader = false;
            });
        });
    }

    createCustomer(intent) {
        return new Promise((resolve, reject) => {
            return this.http.post(environment.apiBaseUrl + '/CreateCustomer', {
                'intent': intent,
                'name': this.member.firstName + ' ' + this.member.lastName,
                'email': this.member.loginEmail
            }).toPromise().then(result => {
                this.member.stripeId = result['data'].id;
                return this.memberService.updateMember(this.member).toPromise().then(() => {
                    resolve(this.member.stripeId);
                    this.showStripeLoader = false;
                    this.dialogService.closeDialog();
                }).catch(err => console.error(err));
            }).catch(err => {
                console.error(err);
                this.showStripeLoader = false;
                reject(err);
            });
        });

    }
}


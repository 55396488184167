import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, combineLatest, Observable, Subject, throwError} from 'rxjs';
import {catchError, distinctUntilChanged, first, map, retry, take, tap, withLatestFrom} from 'rxjs/operators';
import includes from 'lodash/includes';
import {Job, LinkModel, Response} from '../models';
import {environment} from '../../environments';
import {PrismHeaders} from '../enums';
import {refreshJobs$} from '../helpers';
import {collection, getFirestore, onSnapshot, query, Unsubscribe, where} from 'firebase/firestore';
import {FloorplanTicket, VideoTicket} from '../models/ticket';
import {SnackbarService} from './snackbar.service';

@Injectable({
    providedIn: 'root'
})
export class JobsService implements OnInit {
    private isGetJobsLoading: BehaviorSubject<boolean>;
    private isPostJobLoading: BehaviorSubject<boolean>;
    private isPutJobLoading: BehaviorSubject<boolean>;
    private selectedJobBS: BehaviorSubject<Job>;
    private jobSnapshotListener: Unsubscribe;
    private jobs$: BehaviorSubject<Job[]>;
    private invoiceUpdated: Subject<any>;
    private editingJob: BehaviorSubject<boolean>;
    private _usersForJobs: BehaviorSubject<any>;
    private _jobsListPaginationData: BehaviorSubject<any>;
    private _jobDetailsPaginationData: BehaviorSubject<any>;
    private serviceUpdatedInfo: Subject<any>;
    private _jobsOverviewData: BehaviorSubject<any>;
    private _jobOverviewPanelData: BehaviorSubject<any>;
    private isSavingSubject = new BehaviorSubject<boolean>(false);
    isSaving$: Observable<boolean> = this.isSavingSubject.asObservable();


    constructor(private http: HttpClient, private snackbarService: SnackbarService) {
        this.isSaving$ = this.isSavingSubject.asObservable();
        this.isGetJobsLoading = new BehaviorSubject<boolean>(false);
        this.isPostJobLoading = new BehaviorSubject<boolean>(null);
        this.isPutJobLoading = new BehaviorSubject<boolean>(null);
        this.selectedJobBS = new BehaviorSubject<Job>(null);
        this.jobs$ = new BehaviorSubject<Job[]>(null);
        this.invoiceUpdated = new Subject();
        this.editingJob = new BehaviorSubject<boolean>(false);
        this._usersForJobs = new BehaviorSubject<any>(null);
        this._jobsListPaginationData = new BehaviorSubject<any>({
            pageSize: 10,
            pageIndex: 0
        });
        this._jobDetailsPaginationData = new BehaviorSubject<any>({
            pageSize: 10,
            pageIndex: 0
        });
        this.serviceUpdatedInfo = new Subject<any>();
        this._jobsOverviewData = new BehaviorSubject<any>({});
        this._jobOverviewPanelData = new BehaviorSubject<any>(
            {
                numOfJobs: 0,
                'Member Review': 0,
                'In Progress': 0,
                'Not_Invoiced': 0
            }
        );
    }

    ngOnInit() {
    }

    updateJobsDetailsTableSize(size: number) {
        this._jobDetailsPaginationData.next({
                ...this._jobDetailsPaginationData.value,
                pageSize: size
            }
        );
    }

    getServiceTicket(serviceId: string) {
        const job = this.selectedJobBS.value;
        if (job && job.tickets && job.tickets[serviceId]) {
            return this.selectedJobBS.value?.tickets[serviceId];
        }
        return null;
    }

    saveServiceTicket(serviceId: string, ticket: VideoTicket | FloorplanTicket) {
        let job = this.selectedJobBS.value;
        if (!job.tickets) {
            job.tickets = {};
        }
        job.tickets[serviceId] = ticket;
        this.updateSelectedJob(job);

        this.http.post(environment.apiBaseUrl + '/SaveServiceTicket', {
            ticket,
            serviceId,
            jobId: job.id
        }).pipe(take(1)).subscribe(() => null, (error) => console.error(error));
    }

    searchRelevantJobs(filter, searchString, paginationData, userId, isContractor) {
        return this.http.post(environment.apiBaseUrl + '/SearchJobs', {
            ...paginationData, filter, searchString, userId, isContractor
        });
    }

    getJobOverviewPanelData(userId, isContractor) {
        const headers = new HttpHeaders()
            .set(PrismHeaders.QueryData, JSON.stringify({userId, isContractor}));
        return this.http.get(environment.apiBaseUrl + '/GetJobOverviewData', {headers})
            .pipe(
                take(1),
                tap((response: any) => {
                    let {invoiceStatus, statuses, hitsCount} = response.data;
                    let jobsStats = {
                        numOfJobs: 0,
                        'Member Review': 0,
                        'In Progress': 0,
                        Not_Invoiced: 0
                    };
                    jobsStats.numOfJobs = !!hitsCount ? hitsCount : 0;
                    jobsStats['Member Review'] = !!statuses['Member Review'] ? statuses['Member Review'] : 0;
                    jobsStats['In Progress'] = !!statuses['In Progress'] ? statuses['In Progress'] : 0;
                    jobsStats.Not_Invoiced = !!invoiceStatus.Not_Invoiced ? invoiceStatus.Not_Invoiced : 0;
                    this._jobOverviewPanelData.next(jobsStats);
                })
            );
    }

    updateJobsListPagination({pageSize, pageIndex}) {
        this._jobsListPaginationData.next({pageSize, pageIndex});
    }

    getServiceStatuses(jobId) {
        if (this._jobsOverviewData.value[jobId]) {
            return this._jobsOverviewData.value[jobId].statuses ?? {};
        } else {
            return {};
        }
    }

    setJobsOverviewData(jobsOverviewData) {
        this._jobsOverviewData.next({...this._jobsOverviewData.value, ...jobsOverviewData});
    }

    updateJobsOverviewData(jobId, serviceId, status) {
        this.serviceUpdatedInfo.next({jobId, serviceId, status});
        if (!!this._jobsOverviewData.value[jobId]) {
            if (!!this._jobsOverviewData.value[jobId].statuses) {
                this._jobsOverviewData.value[jobId].statuses[serviceId] = status;
                this._jobsOverviewData.next(this._jobsOverviewData.value);
            }
        }
    }

    jobInvoiced() {
        this._jobOverviewPanelData.value['Not_Invoiced']--;
        this._jobOverviewPanelData.next(this._jobOverviewPanelData.value);
        this.invoiceUpdated.next(true);
    }

    jobStatusUpdateListener() {
        return this.serviceUpdatedInfo.asObservable();
    }

    set jobsBSubjectSetter(jobs: Job[]) {
        this.jobs$.next(jobs);
    }

    getJobs(ids, mainMemberUid = null, pageSize, pageIndex) {
        this.switchGetJobsLoaderOn();
        const headers = new HttpHeaders()
            .append(PrismHeaders.QueryData, JSON.stringify({pageSize, pageIndex, ids, mainMemberUid}));

        return this.http.get(environment.apiBaseUrl + '/GetJobs', {
            headers
        })
            .pipe(
                first(d => !!d),
                map((jobsResponse: Response) => {
                    let {allJobs: jobs} = jobsResponse.data;
                    return jobs;
                }),
                tap((jobs: Job[]) => {
                    this.updateTableJobs(jobs);
                    this.switchGetJobsLoaderOff();
                })
            );
    }

    getJob(jobId, mainMemberUid = null): Observable<Job> {
        const jobHeaders = new HttpHeaders().append(PrismHeaders.QueryData, JSON.stringify({jobId, mainMemberUid}));
        return this.http.get<{ data: Job }>(`${environment.apiBaseUrl}/GetJob`, {headers: jobHeaders})
            .pipe(
                map(res => res.data),
                catchError(error => {
                    console.error('Error fetching job:', error);
                    return throwError(error);
                })
            );
    }


    updateTableJobs(jobs = []) {
        this.jobs$.next(jobs);
    }

    postJob(job: Job) {
        const headers = new HttpHeaders()
            .set(PrismHeaders.FunctionHelperData, JSON.stringify({job: encodeURIComponent(JSON.stringify(job))}));

        return this.http.get(environment.apiBaseUrl + '/PostJob', {headers})
            .pipe(
                tap(() => {
                    this._jobOverviewPanelData.value.numOfJobs++;
                    this._jobOverviewPanelData.value['Not_Invoiced']++;
                    this._jobOverviewPanelData.next(this._jobOverviewPanelData.value);
                    refreshJobs$.next();
                }),
                retry(4)
            )
            .toPromise();
    }

    putJob(job) {
        this.switchPutJobLoaderOn();
        const jobId = this.selectedJobBS.getValue().id;
        const headers = new HttpHeaders()
            .set(PrismHeaders.QueryData, JSON.stringify({jobId}));

        return this.http.put(environment.apiBaseUrl + '/PutJob', job, {headers})
            .pipe(
                retry(4),
                tap(() => {
                    refreshJobs$.next();
                })
            )
            .toPromise();
    }

    getAgenciesAndContractorNames(mainMemberUid = null) {
        const headers = new HttpHeaders()
            .append(PrismHeaders.FunctionHelperData, JSON.stringify({mainMemberUid}));

        return this.http.get(`${environment.apiBaseUrl}/GetAgencyAndContractorNames`, {headers})
            .pipe(
                map((response: Response) => response.data),
                tap(({agenciesArray, contractorsArray, agentsArray}) => {
                    this._usersForJobs.next({
                        agenciesArray,
                        contractorsArray,
                        agentsArray
                    });
                })
            );
    }

    setSelectedJob(job: Job) {
        if (!!this.jobSnapshotListener) {
            this.jobSnapshotListener();
        }
        if (!!job) {
            if (!this._jobsOverviewData.value[job.id]) {
                this._jobsOverviewData.value[job.id] = {};
                this._jobsOverviewData.value[job.id].statuses = {};
                this._jobsOverviewData.next(this._jobsOverviewData.value);
            }

            const firestoreRef = getFirestore();
            const collectionReference = collection(firestoreRef, 'memberJobs');
            const queryRef = query(collectionReference,
                where('memberProfileUid', '==', `${job.memberProfileUid}`),
                where('id', '==', job.id));

            this.jobSnapshotListener = onSnapshot(queryRef, (jobsDataSnapshot) => {
                jobsDataSnapshot.forEach((data) => {
                    const hasServices = !!data.data().services;
                    if (hasServices) {
                        let {services} = data.data();
                        Object.entries(services).forEach(([serviceId, status]) => {
                            this._jobsOverviewData.value[job.id].statuses[serviceId] = status;
                        });
                        this._jobsOverviewData.next(this._jobsOverviewData.value);
                    }
                });
            });
        }
        this.selectedJobBS.next(job);
    }

    setEditJob(editingJob: boolean) {
        this.editingJob.next(editingJob);
    }

    jobOverviewPanelData() {
        return this._jobOverviewPanelData.asObservable();
    }

    get jobsTableSize() {
        return this._jobsListPaginationData.value.pageSize;
    }

    get jobDetailsTableSize() {
        return this._jobDetailsPaginationData.value.pageSize;
    }

    get jobServicesStatuses$() {
        return this.jobsOverviewData$
            .pipe(
                withLatestFrom(this.selectedJob$),
                distinctUntilChanged(),
                map(([overviewData, job]) => {
                    return overviewData[job.id].statuses;
                })
            );
    }

    get jobListPaginationData$() {
        return this._jobsListPaginationData.asObservable();
    }

    get jobListPaginationData() {
        return this._jobsListPaginationData.value;
    }

    get jobsOverviewData$() {
        return this._jobsOverviewData.asObservable();
    }

    get jobs(): Observable<Job[]> {
        return this.jobs$.asObservable();
    }

    get jobsValue(): Job[] {
        return this.jobs$.getValue();
    }

    get editJob$() {
        return this.editingJob.asObservable();
    }

    get selectedJob() {
        return this.selectedJobBS.value;
    }

    get selectedJob$(): Observable<Job> {
        return this.selectedJobBS.asObservable();
    }

    get isGetJobsLoading$() {
        return this.isGetJobsLoading.asObservable();
    }

    get isPostJobLoading$() {
        return this.isPostJobLoading.asObservable();
    }

    get isPutJobLoading$() {
        return this.isPutJobLoading.asObservable();
    }

    get areJobsLoading$() {
        return combineLatest(this.isGetJobsLoading$, this.isPostJobLoading$, this.isPutJobLoading$)
            .pipe(
                map(x => includes(x, true))
            );
    }

    initialiseJobsBSubject() {
        this.jobs$.next([]);
    }

    switchGetJobsLoaderOn() {
        this.isGetJobsLoading.next(true);
    }

    switchPutJobLoaderOn() {
        this.isPutJobLoading.next(true);
    }

    switchGetJobsLoaderOff() {
        this.isGetJobsLoading.next(false);
    }

    switchAllJobLoadersOff() {
        this.isGetJobsLoading.next(false);
        this.isPostJobLoading.next(false);
        this.isPutJobLoading.next(false);
    }

    setSelectedJobInvoicesPlaceholder() {
        if (!!this.selectedJobBS.value.id) {
            if (!!this.selectedJobBS.value?.unsyncedInvoices && this.selectedJobBS.value?.unsyncedInvoices.length) {
                this.selectedJobBS.value.unsyncedInvoices = [];
            }

            this.selectedJobBS.value.unsyncedInvoices.push('invoice_id_placeholder');
            this.selectedJobBS.value.productsToInvoice = false;
            this.selectedJobBS.next(this.selectedJobBS.value);
        }
    }

    updateSelectedJob(job: Job) {
        this.selectedJobBS.next(job);
    }

    setSelectedJobChargedState(charged) {
        if (!!this.selectedJobBS.value.id) {
            this.selectedJobBS.value.charged = charged;
            this.selectedJobBS.next(this.selectedJobBS.value);
        }
    }

    saveJobLink(jobId: number, title: string, url: string): Observable<any> {
        const memberLink: LinkModel = {
            jobId,
            title,
            url,
            active: true
        };
        this.isSavingSubject.next(true);
        return this.http.post(environment.apiBaseUrl + '/PostLink', {memberLink}).pipe(
            map(() => {
                this.isSavingSubject.next(false);
            }),
            catchError(error => {
                console.error('Error saving link:', error);
                this.isSavingSubject.next(false);
                return throwError(error);
            })
        );
    }

    editJobLink(memberLink: LinkModel): Observable<any> {

        this.isSavingSubject.next(true);
        return this.http.put(environment.apiBaseUrl + '/PutLink', {memberLink}).pipe(
            map(() => {
                this.isSavingSubject.next(false);
            }),
            catchError(error => {
                console.error('Error editing link:', error);
                this.isSavingSubject.next(false);
                return throwError(error);
            })
        );
    }

    deleteJobLink(jobId: number, url: string): Observable<any> {
        const headers = new HttpHeaders().append(PrismHeaders.FunctionHelperData, JSON.stringify({jobId, url}));

        return this.http.delete(`${environment.apiBaseUrl}/DeleteLink`, {headers}).pipe(
            catchError(error => {
                console.error('Error deleting link:', error);
                return throwError(error);
            })
        );
    }

    toggleJobLink(jobIdFromRoute: number, url: string, active: boolean) {
        return this.http.put(environment.apiBaseUrl + '/ToggleLinkActive', {jobId: jobIdFromRoute, active, url}).pipe(
            catchError(error => {
                console.error('Error toggling link active:', error);
                return throwError(error);
            })
        );
    }
}

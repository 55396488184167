<div class="link-list-container" *ngIf="links.length > 0">
    <div class="scroll-controls">
        <div class="scroll-left" (click)="scrollLeft()">
            <i class="material-icons">chevron_left</i>
        </div>
        <div class="scroll-right" (click)="scrollRight()">
            <i class="material-icons">chevron_right</i>
        </div>
    </div>
    <h3 class="link-header">Links</h3>
    <ul class="link-list" #linkList>
        <li *ngFor="let link of links" class="link-card">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{ link.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <img mat-card-image [src]="getThumbnail(link.url, link.thumbnail)" alt="{{ link.title }} thumbnail">
                </mat-card-content>
                <mat-card-actions>
                    <a [href]="link.url" target="_blank">{{ link.url }}</a>
                    <button mat-icon-button (click)="copyToClipboard(link.url)">
                        <img src="../../../../assets/icons/copying.svg" alt="Copy Link Icon" class="copy-link-icon">
                    </button>
                </mat-card-actions>
            </mat-card>
        </li>
    </ul>
</div>

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {JobsService} from '../../../services';
import {LinkModel} from '../../../models';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-edit-link-dialog',
    templateUrl: './edit-link-dialog.component.html',
    styleUrls: ['./edit-link-dialog.component.scss']
})
export class EditLinkDialogComponent {
    link: LinkModel = {
        jobId: 0,
        index: this.data.index,
        title: this.data.link.title,
        url: this.data.link.url,
        active: true
    };
    saving$: Observable<boolean>;

    constructor(
        private dialogRef: MatDialogRef<EditLinkDialogComponent>,
        private jobsService: JobsService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.link.jobId = data.jobId;
        this.link.index = data.index;
        this.saving$ = this.jobsService.isSaving$;
    }

    save() {
        this.jobsService.editJobLink(this.link)
            .pipe(take(1))
            .subscribe(
                () => {
                    this.dialogRef.close(this.link);
                },
                (error) => {
                    const readableMessage = error?.error?.message || 'Error saving member link';
                    this.snackBar.open(readableMessage, 'Close', {
                        duration: 3000
                    });
                    this.cancel();
                }
            );
    }

    cancel() {
        this.dialogRef.close();
    }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {combineLatest, take, takeUntil, tap} from 'rxjs/operators';
import {ServiceEnum} from '../../enums';
import {Contractor, Job, JobProduct, Member, MemberProduct, Service} from '../../models';
import {AuthenticationService, DialogService, JobsService, ProductsService, SortDirection} from '../../services';
import {AddEditInvoiceComponent} from '../add-edit-invoice/add-edit-invoice.component';
import {AddEditJobComponent} from '../add-edit-job/add-edit-job.component';
import {AddJobProductComponent} from '../add-job-product/add-job-product.component';
import {NotifyAgentDialogComponent} from '../notify-agent-dialog/notify-agent-dialog.component';
import includes from 'lodash/includes';
import {FormUploadDialogComponent} from '../form-upload-dialog/form-upload-dialog.component';
import {ServiceBriefDialogComponent} from '../service-brief/service-brief-dialog/service-brief-dialog.component';

@Component({
    selector: 'prism-job-detail',
    templateUrl: './job-detail.component.html',
    styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit, OnDestroy {
    memberData$: Observable<Member | Contractor>;
    initialTableLoad: boolean;
    isMemberProductsLoading$: Observable<boolean>;
    isJobProductsLoading$: Observable<boolean>;
    isGetJobProductsLoading$: Observable<boolean>;
    isPutJobLoading$: Observable<boolean>;
    selectedJob$: Observable<Job>;
    memberProducts$: Observable<MemberProduct[]>;
    jobProducts$: Observable<JobProduct[]>;
    dropdownProductSelected = false;
    productToAdd: BehaviorSubject<any>;
    dialogRef: MatDialogRef<any>;
    getJobProductsSub: Subscription;
    subDestroyer$: Subject<void>;
    jobInvoiced: boolean;
    albumProducts$: Observable<any[]>;
    jobProductsLoading$: Observable<boolean>;
    memberProductsLoading$: Observable<boolean>;
    loading: Subscription;
    job: Job;
    placeholderText: string;

    constructor(private router: Router,
                private authService: AuthenticationService,
                private jobsService: JobsService,
                private productsService: ProductsService,
                private dialogService: DialogService
    ) {
        this.albumProducts$ = this.productsService.albumProducts$;
        this.jobProductsLoading$ = this.productsService.areJobProductsLoading$;
        this.memberProductsLoading$ = this.productsService.areMemberProductsLoading$;
        this.jobProducts$ = this.productsService.jobProducts$;
        this.selectedJob$ = this.jobsService.selectedJob$;
        this.memberData$ = this.authService.memberData$;
        this.isMemberProductsLoading$ = this.productsService.areMemberProductsLoading$;
        this.isGetJobProductsLoading$ = this.productsService.isGetJobProductsLoading$;
        this.isPutJobLoading$ = this.jobsService.isPutJobLoading$;
        this.productToAdd = new BehaviorSubject<any>(null);
        this.initialTableLoad = true;
        this.jobInvoiced = true;
        this.subDestroyer$ = new Subject<void>();
    }

    ngOnInit() {
        this.productsService.clearAllProducts();
        this.isJobProductsLoading$ = this.productsService.areJobProductsLoading$.pipe(
            tap((loading) => {
                    if (loading === false && this.initialTableLoad === true) {
                        this.initialTableLoad = false;
                    }
                }
            )
        );

        this.loading = this.productsService.areMemberProductsLoading$
            .pipe(
                takeUntil(this.subDestroyer$),
                combineLatest(this.productsService.areJobProductsLoading$)
            )
            .subscribe((loading) => {
                this.placeholderText = includes(loading, true) ? `Loading. Please wait...` : 'Select a product to add';
            });

        this.getJobProducts();

        this.memberProducts$ = this.productsService.memberProducts$;

        if (!this.jobsService.selectedJob) {
            let memberData = this.authService.memberCurrentData;
            let jobId = this.router.url.split('/')[3];
            this.jobsService.getJob(jobId, memberData.mainMemberUid)
                .pipe(take(1))
                .subscribe((job) => {
                    this.jobsService.setSelectedJob(job);
                    this.jobInvoiced = this.isJobInvoiced(job);
                    this.job = job;
                    this.initComponent();
                }, error => {
                    console.error('Error fetching job and links:', error);
                });

        } else {
            this.job = this.jobsService.selectedJob;
            this.initComponent();
            this.jobInvoiced = this.isJobInvoiced(this.job);
        }
    }

    initComponent() {
        this.getJobProductsSub = this.productsService
            .getJobProducts()
            .subscribe(
                () => {
                },
                err => console.error(err),
                () => this.productsService.switchOffAllJobProductLoaders()
            );

        this.selectedJob$
            .pipe(
                takeUntil(
                    this.subDestroyer$
                )
            )
            .subscribe((job) => {
                this.job = job;
                this.jobInvoiced = this.isJobInvoiced(job);
            });
    }

    getJobProducts() {
        this.productsService
            .getMemberProducts({
                getAll: true,
                sortBy: 'ProductName',
                sortDirection: SortDirection.ascending
            })
            .pipe(
                take(1)
            )
            .subscribe();
    }

    navToJobs() {
        this.router.navigate(['home', 'jobs']);
    }

    openAddProductDialog() {
        this.productsService.switchPostJobProductLoaderOn();
        const product = this.productToAdd.value;
        this.productsService.setProductToAdd(product);
        this.dialogRef = this.dialogService.openDialog(AddJobProductComponent, this.productToAdd.value, {
            panelClass: 'add-job-product-dialog'
        });
    }

    openEditJobDialog() {
        this.jobsService.setEditJob(true);
        this.dialogRef = this.dialogService.openDialog(AddEditJobComponent, {}, {width: '600px', panelClass: 'add-edit-job-container'});
    }

    openEditServiceDialog({service, product}: { service: Service, product: JobProduct }) {
        this.productsService.setSelectedService(service);

        const dialogRef = this.dialogService.openDialog(FormUploadDialogComponent, {service, edit: true, product},
            {
                panelClass: 'form-upload-dialog'
            });

        if (service.SNAKE_CASE !== ServiceEnum.IMAGE_PROCESSING && !service.ticketId && service.version === 'v2') {
            dialogRef.afterClosed().subscribe(({cancelled, fileCount, requiresProcessing}: {
                cancelled: boolean,
                fileCount: number,
                requiresProcessing: boolean
            }) => {
                this.dialogService.openDialog(ServiceBriefDialogComponent, {service, cancelled, fileCount},
                    {
                        panelClass: 'form-upload-dialog'
                    });
            });
        }
    }

    isJobInvoiced(job) {
        return (
            ((!!job.invoices && job.invoices.length > 0) && (!job.productsToInvoice)) ||
            ((!!job.unsyncedInvoices && job.unsyncedInvoices.length > 0) && (!job.productsToInvoice)) ||
            this.authService.memberCurrentData.isContractor ||
            ((job.charged === false) && !job.productsToInvoice)
        );
    }

    setSelectedProduct(selection) {
        this.productToAdd.next(selection);
        this.dropdownProductSelected = true;
    }

    navToJobAlbum(product) {
        const jobId = product.jobId;
        this.router.navigate(['home', 'jobs', jobId, 'album']);
    }

    openInvoiceModal() {
        const dialogRef = this.dialogService.openDialog(AddEditInvoiceComponent, {}, {width: '90vw'});
        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe((result: 'charged' | 'not_charged' | 'canceled') => {
                if (result === 'charged') {
                    this.jobsService.setSelectedJobInvoicesPlaceholder();
                    this.job = this.jobsService.selectedJob;
                    this.jobInvoiced = true;
                } else if (result === 'not_charged') {
                    this.jobsService.setSelectedJobChargedState(false);
                    this.job = this.jobsService.selectedJob;
                    this.jobInvoiced = true;
                } else {
                    this.jobInvoiced = false;
                }
            });
    }

    openShareModal() {
        this.dialogService.openDialog(NotifyAgentDialogComponent, {job: this.job}, {width: '600px', autoFocus: false});
    }

    hasActiveProducts(products: MemberProduct[]) {
        return products.filter(product => product.active).length >= 1;
    }

    navToMasterProducts() {
        this.router.navigate(['/home', 'products']);
    }

    ngOnDestroy() {
        this.subDestroyer$.next();
    }
}

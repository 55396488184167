import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil, tap} from 'rxjs/operators';
import {Response} from '../../models';
import {AuthenticationService, MapsAPILoader, MemberService, SnackbarService} from '../../services';
import {EMAIL_REGEX} from '../../helpers';

declare var google: any;

@Component({
    selector: 'prism-personal-information',
    templateUrl: './personal-information.component.html',
    styleUrls: ['./personal-information.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalInformationComponent implements OnInit, OnDestroy {
    loadingMemberData = true;
    freshUntouchedProfileData: { firstName: string; lastName: string; loginEmail: string; secondaryEmail: string; contactNumber: string; postalAddress: string; };
    autocompleteService: any;
    geocoder: any;
    addressVerified = new BehaviorSubject<boolean>(false);
    isAddressVerifying = new BehaviorSubject<boolean>(false);
    addressAutoCompleteOptions = new BehaviorSubject<string[]>([]);
    autoAddressValidSelectionMade: boolean;
    lastAutoSelectionMade: string;
    isContractor: boolean;
    @Input() showCompanyFields: boolean = true;
    personalInformationForm: FormGroup = this.formBuilder.group({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        loginEmail: new FormControl('', [Validators.required]),
        secondaryEmail: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
        contactNumber: new FormControl('', [Validators.required]),
        postalAddress: new FormControl('', [Validators.required]),
        companyName: new FormControl('', [Validators.required]),
        companyId: new FormControl('', [Validators.required]),
    });
    subDestroyer$: Subject<void> = new Subject<void>();

    constructor(private formBuilder: FormBuilder,
                private memberService: MemberService,
                private snackbarService: SnackbarService,
                private mapsAPILoader: MapsAPILoader,
                private authService: AuthenticationService) {
    }

    ngOnInit() {
        this.fetchAndSetMemberProfileData();

        this.mapsAPILoader.load()
            .pipe(takeUntil(this.subDestroyer$))
            .subscribe(() => {
                this.autocompleteService = new google.maps.places.AutocompleteService();
                this.geocoder = new google.maps.Geocoder();

                this.postalAddressControl.valueChanges.pipe(debounceTime(100), distinctUntilChanged())
                    .subscribe(val => {
                        this.isAddressVerifying.next(true);
                        if (val) {
                            this.geocoder.geocode({
                                address: val,
                            }, this.verifyAddressGeocoderResult);

                            this.autocompleteService.getPlacePredictions({
                                types: ['address'],
                                componentRestrictions: {country: ['au', 'nz']},
                                input: val
                            }, this.handleAddressAutocompleteResponse);
                        } else {
                            this.isAddressVerifying.next(false);
                            this.addressVerified.next(false);
                        }
                    });
            });
    }

    ngOnDestroy() {
        this.subDestroyer$.next();
    }

    @Output('personalInformationSaved') personalInfoSaved: EventEmitter<void> = new EventEmitter<void>();

    get firstNameControl() {
        return this.personalInformationForm.get('firstName');
    }

    get lastNameControl() {
        return this.personalInformationForm.get('lastName');
    }

    get loginEmailControl() {
        return this.personalInformationForm.get('loginEmail');
    }

    get secondaryEmailControl() {
        return this.personalInformationForm.get('secondaryEmail');
    }

    get contactNumberControl() {
        return this.personalInformationForm.get('contactNumber');
    }

    get postalAddressControl() {
        return this.personalInformationForm.get('postalAddress');
    }

    get companyNameControl() {
        return this.personalInformationForm.get('companyName');
    }

    get companyIdControl() {
        return this.personalInformationForm.get('companyId');
    }

    get personalRawFormData() {
        return this.personalInformationForm.getRawValue();
    }

    getMemberData(updateData?) {
        return this.memberService.getMember(updateData)
            .pipe(
                tap(({data}: Response) => {
                    this.populateFields(data);
                    this.loadingMemberData = false;
                })
            );
    }

    saveAccount() {
        this.personalInformationForm.disable();
        this.loadingMemberData = true;
        this.memberService.updateMember(this.personalRawFormData)
            .toPromise()
            .then(({data: memberProfileData}: Response) => {
                this.snackbarService.showSnackbar('Your profile has been updated!');
                this.fetchAndSetMemberProfileData(memberProfileData);
                this.personalInfoSaved.emit();
            })
            .catch(err => {
                console.error('ERROR UPDATING MEMBER:', err);
            });
    }

    populateFields(memberData) {
        const {
            firstName,
            lastName,
            loginEmail,
            secondaryEmail,
            contactNumber,
            postalAddress,
            companyName,
            companyId
        } = memberData;
        this.freshUntouchedProfileData = {firstName, lastName, loginEmail, secondaryEmail, contactNumber, postalAddress};
        this.loginEmailControl.enable();
        this.firstNameControl.setValue(firstName);
        this.lastNameControl.setValue(lastName);
        this.loginEmailControl.setValue(loginEmail);
        this.loginEmailControl.updateValueAndValidity();
        this.secondaryEmailControl.setValue(secondaryEmail);
        this.contactNumberControl.setValue(contactNumber);
        this.postalAddressControl.setValue(postalAddress);
        this.companyNameControl.setValue(companyName);
        this.companyIdControl.setValue(companyId);
        this.personalInformationForm.updateValueAndValidity();
        this.loginEmailControl.disable();
        this.personalInformationForm.markAsPristine();
        this.loadingMemberData = false;
    }

    fetchAndSetMemberProfileData(updateData?) {
        this.getMemberData(updateData)
            .toPromise()
            .then((memberResponse: Response) => {
                const memberData = memberResponse.data;
                this.authService.updateMemberData(memberData);
                this.populateFields(memberData);
            })
            .catch(err => {
                console.error('Could not fetch member profile data:', err);
                this.snackbarService.handleError('There was an error fetching your information. Please refresh page.');
            });
    }

    verifyAddressGeocoderResult = (resultList) => {
        if (resultList && Array.isArray(resultList) && resultList.length) {
            const address = this.postalAddressControl.value.toLowerCase();
            const matchingResult = resultList.find(result => {
                if (result.address_components && result.address_components.length >= 3) {
                    return [result.address_components[0], result.address_components[1], result.address_components[2]].every(component => {
                        return (address.includes(component.long_name.toLowerCase()) || address.includes(component.short_name.toLowerCase()));
                    });
                } else {
                    return false;
                }
            });
            if (matchingResult) {
                this.addressVerified.next(true);
            } else {
                this.addressVerified.next(false);
            }
            this.isAddressVerifying.next(false);
        } else {
            this.addressVerified.next(false);
            this.isAddressVerifying.next(false);
        }
    };

    handleAddressAutocompleteResponse = (placeList) => {
        if (placeList && Array.isArray(placeList) && placeList.length) {
            this.addressAutoCompleteOptions.next(placeList.map(place => place.description));
        }
    };

    autoAddressSelectionMade(option, forceNonValidity = false) {
        this.lastAutoSelectionMade = option;
        this.autoAddressValidSelectionMade = forceNonValidity;
        this.autoAddressValidSelectionMade = !!option;
    }
}

import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {LinkModel} from '../../../models';
import {EditLinkDialogComponent} from '../edit-link-dialog/edit-link-dialog.component';
import {DialogService, JobsService, SnackbarService} from '../../../services';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-link-list',
    templateUrl: './link-list.component.html',
    styleUrls: ['./link-list.component.scss']
})
export class LinkListComponent implements AfterViewInit {
    @Input() links: LinkModel[] = [];
    @ViewChild('linkList') linkList: ElementRef<HTMLUListElement>;

    constructor(
        private jobsService: JobsService,
        private snackbarService: SnackbarService,
        private dialogService: DialogService,
        private dialog: MatDialog,
        private router: Router,
    ) {
    }

    ngAfterViewInit(): void {
    }

    scrollLeft(): void {
        if (this.linkList) {
            this.linkList.nativeElement.scrollBy({
                left: -400,
                behavior: 'smooth'
            });
        }
    }

    scrollRight(): void {
        if (this.linkList) {
            this.linkList.nativeElement.scrollBy({
                left: 400,
                behavior: 'smooth'
            });
        }
    }

    deleteLink(link: LinkModel, event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        if (confirm('Are you sure you want to delete this link?')) {
            this.jobsService.deleteJobLink(this.getJobIdFromRoute(), link.url).subscribe(
                () => {
                    this.links = this.links.filter(l => l.url !== link.url);
                    this.snackbarService.showSnackbar('Link successfully deleted!');
                },
                (error) => {
                    console.error('Error deleting link:', error);
                    this.snackbarService.showSnackbar('Failed to delete link');
                }
            );
        }
    }

    openEditDialog(link: LinkModel, index: number): void {
        const dialogRef = this.dialog.open(EditLinkDialogComponent, {
            data: {
                jobId: this.getJobIdFromRoute(),
                link: link,
                index: index
            }
        });
        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    this.snackbarService.showSnackbar('Link updated successfully!');
                    this.links[index] = {
                        jobId: result.jobId,
                        title: result.title,
                        url: result.url,
                        active: result.active
                    };
                    this.jobsService.getJob(this.getJobIdFromRoute())
                        .pipe(take(1))
                        .subscribe((job) => {
                                this.jobsService.setSelectedJob(job);
                            },
                            (error) => {
                                console.error('Error reloading job:', error);
                            }
                        );
                }
            });
    }

    toggleOptions(link: any, event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        link.showOptions = !link.showOptions;
    }

    getJobIdFromRoute(): number {
        return Number(this.router.url.split('/')[3]);
    }

    toggleLinkActive(link: LinkModel): void {
        this.jobsService.toggleJobLink(this.getJobIdFromRoute(), link.url, !link.active)
            .pipe(take(1))
            .subscribe(
                () => {
                    link.active = !link.active;
                    this.snackbarService.showSnackbar(`Link ${link.active ? 'activated' : 'deactivated'} successfully!`);
                },
                (error) => {
                    console.error('Error updating link status:', error);
                    this.snackbarService.showSnackbar('Failed to update link status');
                }
            );
    }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {AgentJobViewComponent} from 'src/app/components/agent-job-view/agent-job-view.component';
import {AgentServiceBucketComponent} from 'src/app/components/agent-job-view/agent-service-bucket/agent-service-bucket.component';
import {ServiceBucketDialogComponent} from 'src/app/components/service-bucket-dialog/service-bucket-dialog.component';
import {
    AgentDownloadSelectionModalComponent
} from '../../components/agent-download-selection-modal/agent-download-selection-modal.component';
import {AgentRouting} from '../routing/agent.routing';
import {HomeModule} from './home.module';
import {PipeModule} from './pipe.module';
import {SharedModule} from './shared.module';
import {LinkListComponent} from '../../components/agent-job-view/link-list-bucket/link-list.component';

// ======= AGENT MODULE SETUP ======= //

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        PipeModule,
        HomeModule,
        AgentRouting
    ],
    declarations: [
        AgentJobViewComponent,
        AgentServiceBucketComponent,
        ServiceBucketDialogComponent,
        AgentDownloadSelectionModalComponent,
        LinkListComponent
    ]
})

export class AgentModule {
}
